<template>

  <div>

    <the-modal class="col-lg-8 offset-lg-2" v-show="videoActive">
      <b-icon-x id="close-x" @click="closeEverything"
        style="position: absolute; color: white; z-index: 150; right: -50px; height: 35px; width: 35px; opacity: .5;"
        class="d-none d-lg-inline"></b-icon-x>
      <video class="embed-responsive embed-responsive-16by9 video" controls ref="indexVideo"
        src="https://unityworks-a.akamaihd.net/anon.unityworks/2/3/K/48884091_Digital_Dealer_Program_2.0_230221b_230221102527_4.mp4"
        type='video/mp4'>
      </video>
    </the-modal>

    <b-row class="w-100 ml-auto mr-auto index-content" style="max-width: 1200px;">
      <b-col xl="auto" id="hero-message-container">

        <div style="position: absolute; height: 120px; width: 100%; z-index: 100;" class="d-none d-md-block">
          <transition name="fadeFirst">
            <h1 v-if="showFirstMessage" class="hero-message-title" style="position: absolute; width: 100%;">{{
            activeHeroTitle| capitalizeAll }}</h1>
          </transition>
          <transition name="fadeSecond">
            <h1 v-if="showFirstMessage" class="hero-message-description"
              style="position: absolute; width: 100%; margin-top: 40px;">{{ activeHeroDescription| capitalizeAll }}</h1>
          </transition>

          <transition name="fadeFirst">
            <h1 v-if="showSecondMessage" class="hero-message-title" style="position: absolute; width: 100%;">{{
            activeHeroTitle| capitalizeAll }}</h1>
          </transition>
          <transition name="fadeSecond">
            <h1 v-if="showSecondMessage" class="hero-message-description"
              style="position: absolute; width: 100%; margin-top: 40px;">{{ activeHeroDescription| capitalizeAll }}</h1>
          </transition>

          <transition name="fadeFirst">
            <h1 v-if="showThirdMessage" class="hero-message-title" style="position: absolute; width: 100%;">{{
            activeHeroTitle| capitalizeAll }}</h1>
          </transition>
          <transition name="fadeSecond">
            <h1 v-if="showThirdMessage" class="hero-message-description"
              style="position: absolute; width: 100%; margin-top: 40px;">{{ activeHeroDescription| capitalizeAll }}</h1>
          </transition>
        </div>

        <div style="position: absolute; height: 80px; width: 100%; z-index: 100;" class="d-md-none">
          <h1 class="hero-message-title" style="position: absolute; width: 100%;">{{
            this.$store.state.data.heroMessages[0].title | capitalizeAll
          }}</h1>
          <h1 class="hero-message-description" style="position: absolute; width: 100%; margin-top: 40px;">{{
            this.$store.state.data.heroMessages[0].description | capitalizeAll
          }}</h1>
        </div>

        <img @click="playVideo()" id="video-thumb" src="@/assets/video_thumb.jpg" />
        <div id="index-text-box">
          <p class="text-white" style="font-size: 18px;">The Acura Digital Dealer Program (ADDP) offers your dealership
            a suite of digital products and services designed to optimize the way you do business.</p>
        </div>
        <a target="_blank" href="https://forms.office.com/pages/responsepage.aspx?id=9SfFtYieMku_u41EJZYWWqBvtwJUpIdCiAxHMOrJyRhUNUowSFRGNlk3MDRPSE9SQVJXUlZNRUlJVC4u&web=1&wdLOR=c9ACF71F0-C717-40C4-93B5-18A49280884D">
          <button type="button" class="btn-acura-primary mt-4">
            <!-- <img style="width: 20px; height: 20px; margin-right: 10px; padding-bottom: 1px;" src="@/assets/rocket.svg"> -->
            SCHEDULE CONSULTATION
          </button>
        </a>
        <!-- <router-link to="/about-the-program">
          <button type="button" class="btn-acura-primary mt-4">
            <img style="width: 20px; height: 20px; margin-right: 10px; padding-bottom: 1px;" src="@/assets/rocket.svg">
              GET STARTED
          </button>
        </router-link> -->
      </b-col>
    </b-row>

    <b-row id="cover-img"></b-row>

  </div>

</template>

<script>

import TheModal from '@/components/TheModal.vue'
import { BIconX } from 'bootstrap-vue'

export default {
  name: 'home',
  components: {
    TheModal,
    BIconX
  },

  metaInfo: {
    title: 'Home'
  },

  data() {
    return {
      formErrors: {},
      currentTime: 0,
      heroMessages: this.$store.state.data.heroMessages,
      activeHeroTitle: this.$store.state.data.heroMessages[0].title,
      activeHeroDescription: this.$store.state.data.heroMessages[0].description,
      showFirstMessage: true,
      showSecondMessage: false,
      showThirdMessage: false,
    };
  },

  watch: {
    $route: "fetchData",
    videoActive: function () {
      if (!this.videoActive) {
        // this.resetSupportForm();
        this.stopVideo();
      }
    }
  },

  created() {
    this.startTimer();
  },

  methods: {

    playVideo() {
      window.scrollTo(0, 0);
      this.indexVideo.play();
      this.$store.commit("toggleModal");
      this.$store.commit("toggleVideo");
    },

    stopVideo() {
      this.indexVideo.pause();
      this.indexVideo.currentTime = 0;
    },

    closeEverything() {
      this.$store.commit("closeEverything");
    },

    startTimer() {
      setInterval(this.setHeroStuff, 1000);
    },

    setHeroStuff() {

      // console.log(this.currentTime);

      if (this.currentTime == 1) {
        this.activeHeroTitle = this.heroMessages[0].title;
        this.activeHeroDescription = this.heroMessages[0].description;
        this.showFirstMessage = true;
      }

      if (this.currentTime == 4) {
        this.showFirstMessage = false;
      }

      if (this.currentTime == 5) {
        this.showSecondMessage = true;
        this.activeHeroTitle = this.heroMessages[1].title;
        this.activeHeroDescription = this.heroMessages[1].description;
      }

      if (this.currentTime == 8) {
        this.showSecondMessage = false;
      }

      if (this.currentTime == 9) {
        this.showThirdMessage = true;
        this.activeHeroTitle = this.heroMessages[2].title;
        this.activeHeroDescription = this.heroMessages[2].description;
      }

      if (this.currentTime == 12) {
        this.showThirdMessage = false;
        this.currentTime = 0;
      }

      this.currentTime = this.currentTime + 1;

    },

  },

  computed: {

    videoActive() {
      return this.$store.state.videoActive ? true : false;
    },

    indexVideo() {
      return this.$refs.indexVideo;
    },

  }
}
</script>

<style scoped>
.fadeFirst-enter {
  opacity: 0;
  margin-left: 50px;
}

.fadeFirst-enter-active {
  transition: all 0.3s ease-in-out;
}

.fadeFirst-leave-active {
  transition: all 0.3s ease-in-out;
}

.fadeFirst-leave-to {
  opacity: 0;
  margin-left: 80px;
}

.fadeSecond-enter {
  opacity: 0;
  margin-left: -50px;
}

.fadeSecond-enter-active {
  transition: all 0.3s ease-in-out;
}

.fadeSecond-leave-active {
  transition: all 0.3s ease-in-out;
}

.fadeSecond-leave-to {
  opacity: 0;
  margin-left: 80px;
  margin-top: 10px;
}

#close-x:hover {
  cursor: pointer;
  opacity: 1;
}

@media screen and (min-width: 1400px) {

  #hero-message-container {
    width: 90%;
    max-width: 1000px;
    position: absolute;
    z-index: 50;
    margin-top: 70px;
  }

  .video {
    position: absolute;
    z-index: 250;
    background-color: black;
    border-radius: 5px;
  }

  .video:focus {
    outline: none;
  }

  #cover-img {
    position: abolute;
    z-index: 5;
    background-image: url('../assets/tlx_bg.jpg');
    background-repeat: no-repeat;
    background-position: 35%;
    background-size: cover;
    border-bottom: 3px solid rgb(232, 44, 42);
    height: 800px;
  }

  #video-thumb {
    margin-top: 200px;
    width: 240px;
    -webkit-box-shadow: 2px 2px 4px 1px rgba(0, 0, 0, 0.5);
    box-shadow: 2px 2px 4px 1px rgba(0, 0, 0, 0.2);
  }

  #video-thumb:hover {
    filter: brightness(1.15);
    -webkit-box-shadow: none;
    box-shadow: none;
    cursor: pointer;
  }

  #index-text-box {
    width: 430px;
    margin-top: 50px;
    line-height: 1.5em;
    text-shadow: 0px 0px 4px rgba(0, 0, 0, .7);
  }

  .hero-message-title {
    color: white;
    font-weight: 300;
    text-shadow: 0px 0px 4px rgba(0, 0, 0, .7);
  }

  .hero-message-description {
    color: white;
    font-weight: 700;
    font-size: 35px;
    margin-top: -15px;
    text-shadow: 0px 0px 4px rgba(0, 0, 0, .7);
  }
}

@media screen and (max-width: 1399px) {

  #hero-message-container {
    position: absolute;
    z-index: 50;
    width: 90%;
    max-width: 1200px;
    margin-top: 30px;
  }

  .video {
    position: absolute;
    z-index: 250;
    background-color: black;
    border-radius: 5px;
  }

  .video:focus {
    outline: none;
  }

  #cover-img {
    position: abolute;
    z-index: 5;
    background-image: url('../assets/tlx_bg.jpg');
    background-repeat: no-repeat;
    background-position: 40%;
    background-size: cover;
    border-bottom: 3px solid rgb(232, 44, 42);
    height: 800px;
  }

  #video-thumb {
    margin-top: 200px;
    width: 200px;
    -webkit-box-shadow: 2px 2px 4px 1px rgba(0, 0, 0, 0.5);
    box-shadow: 2px 2px 4px 1px rgba(0, 0, 0, 0.2);
  }

  #video-thumb:hover {
    filter: brightness(1.15);
    -webkit-box-shadow: none;
    box-shadow: none;
    cursor: pointer;
  }

  #index-text-box {
    width: 430px;
    margin-top: 40px;
    line-height: 1.5em;
    text-shadow: 0px 0px 4px rgba(0, 0, 0, .7);
  }

  .hero-message-title {
    color: white;
    font-weight: 300;
    font-size: 30px;
    line-height: 1.0;
    text-shadow: 0px 0px 4px rgba(0, 0, 0, .7);
  }

  .hero-message-description {
    color: white;
    font-weight: 700;
    font-size: 20px;
    margin-top: 20px;
    text-shadow: 0px 0px 4px rgba(0, 0, 0, .7);
  }
}

@media screen and (max-width: 768px) {

  .index-content {
    padding-top: 60px;
  }

  .video {
    position: fixed;
    width: 100%;
    right: 0px;
    border-radius: 0px;
    margin-top: 25%;
    z-index: 400;
  }

  #video-thumb {
    margin-top: 150px;
  }

  #hero-message-container {
    width: 90%;
    margin-top: 45px;
  }

  #index-text-box {
    width: 100%;
  }

  #cover-img {
    position: abolute;
    z-index: 5;
    background-image: url('../assets/tlx_bg.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-bottom: 3px solid rgb(232, 44, 42);
    height: 700px;
  }

}
</style>